export const environment = {
  address: {
    name: 'Auf der Mauer 8',
    src: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d567.9773003634551!2d8.544542745287506!3d47.37739587420706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa0a7ea8e0557%3A0x580a691a5080e031!2sAuf%20der%20Mauer%208%2C%208001%20Z%C3%BCrich%2C%20Switzerland!5e0!3m2!1sen!2sgr!4v1731847680212!5m2!1sen!2sgr',
    zipCode: 'CH-8001, Zurich'
  },
  apiURL: 'https://platform.aero-fp.com',
  appVersion: require('../../package.json').version,
  background: 'aero-background.png',
  logo: 'aero-logo.png',
  name: 'Aero',
  production: true,
  sentryDSN: 'https://3434ab7359f1425b8982cbd45d069ae9@o4508110298284032.ingest.de.sentry.io/4508110300250192',
  sentryEnvironment: 'aero-production'
};
